<template>
	<div class="reuslts-page centering">
		<div class="banner-container">
			<div class="inner"></div>
		</div>

		<div class="text-container centering directory-txt">
			<h2>ΒΙΟΓΡΑΦΙΚΟ ΣΗΜΕΙΩΜΑ του ΑΘΑΝΑΣΙΟΥ ΟΙΚΟΝΟΜΟΠΟΥΛΟΥ</h2>
			<p>Ο Αθανάσιος Οικονομόπουλος γεννήθηκε στη Θεσσαλονίκη το 1953</p>
			<p>Κατέχει δίπλωμα μηχανολόγου-ηλεκτρολόγου από το Εθνικό Μετσόβιο Πολυτεχνείο Αθηνών και διδακτορικό (Diplôme de Docteur Ingénieur) από το πανεπιστήμιο Pierre et Marie Curie των Παρισίων.</p>
			<p>Από το 1978 μέχρι το τέλος του 1980 εργάστηκε στο Εργαστήριο LIMSI του Γαλλικού Εθνικού Κέντρου Ερευνών (CNRS), από το 1980 έως το 1984 ως βοηθός καθηγητής στο Ομοσπονδιακό Πολυτεχνείο της Λωζάννης (École Polytecnique Fédérale de Lausanne) και από 1984 μέχρι το 1987 διηύθυνε το πιλοτικό πρόγραμμα ρομποτικής στα πλαίσια της δράσης ESPRIT της ΕΕ στις Βρυξέλλες.</p>
			<p>Το 1998 ίδρυσε την εταιρεία ΖΕΝΟΝ robotics στην Ελλάδα και διετέλεσε  διευθύνων σύμβουλος και πρόεδρος του ΔΣ μέχρι τον Ιούλιο του 2008 όταν και αποσύρθηκε οριστικά από τη διοίκηση και τη μετοχική της σύνθεση. Στην 20ετή εν λόγω πορεία της η ΖΗΝΩΝ υλοποίησε περισσότερα από 50 έργα εγκατάστασης ρομποτικών εφαρμογών στην Ελληνική βιομηχανία και περισσότερα από 100 ερευνητικά συμβόλαια με ξένες εταιρείες.</p>
			<div class="centering">
				<div class="collector">
					<div class="center">
						<img src="@/assets/img/image_2024_11_04T08_31_33_904Z.png" alt="">
					</div>
				</div>
			</div>
			<p>Παράλληλα με την ακαδημαϊκή και επιχειρηματική του δραστηριότητα συγκρότησε μία συλλογή βιβλίων με εκδόσεις ελληνικών κειμένων από συγγραφείς που ήκμασαν από την εποχή του Ομήρου μέχρι την πτώση της Κωνσταντινούπολης και εκδόθηκαν από τον 15ο έως τα τέλη του 19ου αιώνα. Η συλλογή αυτή, με το όνομα Bibliotheca Graeca, είναι το μοναδικό corpus επί του συγκεκριμένου αντικειμένου στην Ελλάδα και ένα από τα σημαντικότερα παγκοσμίως.</p>
			<p>Μέρος της συλλογής παραχωρήθηκε το 2014 στο ίδρυμα Αικατερίνης Λασκαρίδη και είναι στη διάθεση του αναγνωστικού κοινού.</p>
			<p>Από το 2008 και μέχρι σήμερα ο Α.Οικονομόπουλος αφιέρωσε τον χρόνο του στον συνεχή εμπλουτισμό της βιβλιοθήκης αυτής, την τεκμηρίωση και την καταλογογράφηση της, καθώς και την συγγραφή δύο σχετικών πονημάτων: τον Θησαυρό της Ελληνικής γραμματείας (2017) και το φιλοσοφικό λεξικό Έργα και ημέρες των Ελλήνων φιλοσόφων (2023). Και τα δύο αυτά βιβλία περιλαμβάνουν πρωτότυπα λήμματα για τους Έλληνες συγγραφείς της αρχαιότητας, τα έργα και τις εκδόσεις τους.</p>
			<p>Το σύνολο των τεκμηρίων που συγκεντρώθηκαν, συμπεριλαμβανομένων 30,000 πρωτότυπων φωτογραφιών των βιβλίων και των περιεχομένων τους, περιλαμβάνονται σε 13,000 ηλεκτρονικές καρτέλες και έχουν καταχωρηθεί στην παρούσα  διαδικτυακή εφαρμογή.</p>
			<p>Ο Α.Οικονομόπουλος έχει δημοσιεύσει τέσσερα βιβλία:</p>
			<p>
				1)	Θησαυρός της Ελληνικής γραμματείας (2019)<br>
				2)	Ο πόλεμος της Τροίας δεν τελείωσε (2021).<br>
				3)	Η Θεσσαλονίκη υπό το βλέμμα των θεών, μετ. μυθιστορήματος του Jean Frappa (2022)<br>
				4)	Από τον Θαλή τον Μιλήσιο στον Πλήθωνα τον Γεμιστό: έργα και ημέρες των Ελλήνων φιλοσόφων (2023)
			</p>
		</div>
	</div>
</template>

<script>

export default {
	name: 'SearchResults',
	components: {
	},
	data: () => {
		return {
		}
	},
	computed: {

	},
	methods: {

	},
	mounted() {
	},
	watch: {
		currentApiPage(x, y) {
			// console.log("changed")
		}
	}
}
</script>

<style scoped lang="scss">
.directory-txt {
		text-align: center;
		margin-bottom: 1rem;

		h2 {
			line-height: 1.6;
			font-size: calc(14px + (50 - 14) * ((100vw - 300px) / (1600 - 300)));
			margin-bottom: 2rem;
		}

	}

	.fadeIn-enter-active {
		opacity: 1;
		transition: all 1s ease;
	}

	.fadeIn-enter {
		opacity: 0;
	}

	.reuslts-page {
		background-image: url('../assets/img/bg_2.jpg');
		background-size: cover;
		background-repeat: no-repeat;
	}

	.banner-container {
		width: 100%;
		height: 20vw;

		.inner {
			background-image: url('../assets/img/image_2024_11_04T08_31_28_265Z.png');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			height: 100%;
			width: 100%;
		}
	}

	.text-container {
		padding: 5% 10%;
		width: 75%;
		max-width: 1250px;
		align-items: flex-start;

		h2 {
			font-size: calc(14px + (25 - 14) * ((100vw - 300px) / (1600 - 300)));
			font-weight: bold;
		}

		p {
			text-align: justify;
			font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
			line-height: 1.4;
		}
	}

	.books {
		width: 100%;
		height: 20vw;

		.inner {
			background-image: url('../assets/img/photo_books_1.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center bottom;
			background-color: transparent;
			width: 100%;
			padding: 10% 0;
		}
	}

	.divider {
		width: 10%;
		border-bottom: 1px solid #000;
		margin: 5% 0 2.5% 0;
	}

	@media(max-width: 720px) {
	.text-container {
		width: 90%;
	}

}

	@media(max-width: 560px) {
	}
</style>
