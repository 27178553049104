<template>
	<div class="contact-form centering">
		<form @submit.prevent="formSumbit">
			<md-field md-clearable>
				<label>ΟΝΟΜΑ</label>
				<md-input v-model="noErrorText" required></md-input>
			</md-field>

			<md-field md-clearable :class="messageClass">
				<label>EMAIL</label>
				<md-input v-model="requiredMail" required></md-input>
				<span class="md-error">Παρουσιάστηκε Σφάλμα</span>
			</md-field>

			<md-field md-clearable :class="messageClass">
				<label>MΗNΥMA</label>
				<md-textarea v-model="msg" required></md-textarea>
				<span class="md-error">Παρουσιάστηκε Σφάλμα</span>
			</md-field>

			<div class="btn-container">
				<md-button type="submit" class="md-dense send-btn md-raised md-primary">Αποστολη</md-button>
			<!-- <md-switch v-model="hasMessages">{{ !hasMessages ? 'Show' : 'Hide' }} Errors</md-switch> -->
				<md-snackbar :md-active.sync="msgSent">Το μήνυμα εστάλη επιτυχώς!</md-snackbar>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name: 'ContactForm',
	data: () => ({
		noErrorText: null,
		requiredMail: null,
		msg: null,
		hasMessages: false,
		msgSent: false,
		duration: 3000
	}),
	computed: {
		messageClass () {
			return {
				'md-invalid': this.hasMessages
			}
		}
	},
	methods: {
		formSumbit(e) {
			this.$http
				.post(`${this.$store.state.rootUrl}contact`, {
					name: this.noErrorText,
					email: this.requiredMail,
					message: this.msg
				})
				.then((response) => {
					if (response.status === 200) {
						this.msgSent = true
					}
				})
				.catch((error) => {
					alert(error)
				})
		}
	}
}
</script>

<style scoped lang="scss">

form {
	width: 100%;
}

.md-field.md-theme-default:after {
    background-color: #979797;
}

.md-field.md-theme-default.md-has-value .md-input {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.85);
}

.btn-container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.md-field.md-theme-default.md-focused label {
	color: #f9b600!important;
}
.md-field .md-theme-default .md-clearable .md-focused .md-required .md-has-value .md-has-textarea {

	textarea {
		border-color: #000;
	}
}
.md-field.md-theme-default.md-has-textarea:not(.md-autogrow):before {
	border: none;
}

.send-btn {
	margin: 0;
	background-color: #f9b600!important;
	font-weight: 400;
	font-family: 'Roboto', sans-serif;
}

.md-snackbar {
	background-color: green!important;
	color: #fff;
}
</style>
